import { Routes, Route } from "react-router-dom";

import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer";
import { HomePage } from "./pages/Home";
import { NotFoundPage } from "./pages/NotFound";
import { BackToTopButton } from "./components/Buttons/BackToTopButton";
import { CookieBanner } from "./components/CookieBanner/CookieBanner";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <BackToTopButton />
      <CookieBanner />
      <Footer />
    </>
  );
}
export default App;
