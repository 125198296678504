const footerLinkNames = {
  DevelopmentSolutions: [
    { id: 1, name: "Databases" },
    { id: 2, name: "Back End Developemnt" },
    { id: 3, name: "Front End Development" },
    { id: 4, name: "Cloud Technologies" },
  ],
  Company: [
    { id: 1, name: "About" },
    { id: 2, name: "Careers" },
  ],
  Legal: [
    { id: 1, name: "Policy" },
    { id: 2, name: "Terms" },
  ],
};

export { footerLinkNames };
