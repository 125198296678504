import React from "react";

const FooterLinks = ({ collection, headerName }) => {
  return (
    <div>
      <h6 className="font-medium text-gray-400">{headerName}</h6>
      <ul>
        {collection.map(({ name, id }) => (
          <li key={id} className="py-2 text-sm pl-0">
            {name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterLinks;
