import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";

import { LogoWithName } from "../Logo";

const links = [];
function Navbar() {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <nav className="border-gray-200 bg-gray-900">
      <div className="flex flex-wrap justify-between items-center max-w-screen-xl mx-auto p-4 text-white">
        <LogoWithName />
        <ul className="hidden md:flex">
          {links.map(({ name, id }) => (
            <li key={id}>{name}</li>
          ))}
        </ul>

        <div onClick={handleNav} className="block md:hidden">
          {nav ? <AiOutlineClose size={28} /> : <AiOutlineMenu size={34} />}
        </div>
        {/* <div
          className={
            nav
              ? "fixed left-0 top-0 w-[60%] h-full border-r-gray-900 bg-secondary ease-in-out duration-500"
              : "fixed left-[-100%]"
          }
        >
          <h1 className="w-full text-3xl font-bold text-primary m-4">
            LicomSync<span className="text-yellowOrange">.</span>
          </h1>
          <ul className="uppercase">
            {links.map(({ name, id }) => (
              <li className="border-b border-gray-600" key={id}>
                {name}
              </li>
            ))}
          </ul>
        </div> */}
      </div>
    </nav>
  );
}

export { Navbar };
