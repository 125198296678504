import React from "react";

import { LogoWithName } from "../Logo";
import { footerLinkNames } from "../../utils";
import FooterLinks from "./FooterLinks";
import SocialMediaLinks from "./SocialMediaLinks";

function Footer() {
  return (
    <footer>
      <div className="max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-white">
        <div>
          <LogoWithName />
          <p className="py-4">
            At LicomSync, we are dedicated to driving a technological revolution
            by providing innovative, cutting-edge solutions that synchronize
            your digital world. As a premier software development company, we
            specialize in crafting customized solutions that optimize
            efficiency, streamline processes, and elevate your digital
            experiences to new heights.
          </p>
          <SocialMediaLinks />
        </div>
        <div className="lg:col-span-2 flex justify-between md:px-32">
          <FooterLinks
            collection={footerLinkNames.DevelopmentSolutions}
            headerName="Development Solutions"
          />
          <FooterLinks
            collection={footerLinkNames.Company}
            headerName="Company"
          />
        </div>
      </div>
      <span className="text-center block text-sm sm:text-center text-white">
        © {new Date().getFullYear()}{" "}
        <a href="/" className="hover:underline">
          LicomSync.
        </a>
      </span>
    </footer>
  );
}

export { Footer };
