import React from "react";
import { FaFacebookSquare, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const SocialMediaLinks = () => {
  return (
    <div className="flex gap-8 my-6 md: w-[75%]">
        <a
          href="https://www.facebook.com/profile.php?id=100095313244474"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebookSquare size={30} className="hover:text-[#1877F2]" />
        </a>
        <a
          href="https://www.linkedin.com/company/licomsync/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedinIn size={30} className="hover:text-[#0A66C2]" />
        </a>
        <a
          href="https://twitter.com/LicomSync"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaXTwitter size={30} className="hover:text-[#1DA1F2]" />
        </a>
    </div>
  );
};

export default SocialMediaLinks;
