import React from "react";

import logo from "../../assets/logo.png";

function LogoWithName() {
  return (
    <a href="/" className="flex items-center">
      <img src={logo} className="h-8 mr-3" alt="LicomSync Logo" />
      <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">
        LicomSync
      </span>
    </a>
  );
}
export { LogoWithName };
