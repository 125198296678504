import React, { useEffect } from "react";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
import { initGA } from "../../utils";

function CookieBanner() {
  const handleAcceptCookie = () => {
    const trackingId = process.env.LICOMSYNC_WEB_GOOGLE_ANALYTICS_ID;
    if (trackingId) {
      initGA(trackingId);
    }
  };

  const handleDeclineCookie = () => {
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    getCookieConsentValue() && handleAcceptCookie();
  }, []);

  return (
    <CookieConsent
      cookieName="a_bol"
      location="bottom"
      debug={true} //to be removed before deployment
      enableDeclineButton
      onAccept={handleAcceptCookie}
      onDecline={handleDeclineCookie}
      buttonText="Accept"
      style={{ background: "#1E90FF" }}
      buttonStyle={{
        color: "#ffff",
        backgroundColor: "#64c466",
        fontWeight: 600,
        borderRadius: "6px",
        margin: "16px",
      }}
      declineButtonText="Decline"
      declineButtonStyle={{
        color: "#ffff",
        backgroundColor: "#858583",
        fontWeight: 600,
        borderRadius: "6px",
        margin: "16px",
      }}
    >
      We use cookies to enhance your experience on our website. we use cookies
      to ensure proper website functionality, analyze traffic, and personalize
      content. By clicking "Accept", you consent to the use of all cookies.
    </CookieConsent>
  );
}
export { CookieBanner };
