import React from "react";
import { Software } from "./Software";
import { SVGLoader } from "../../components/Loaders";

function HomePage() {
  document.title = "Home - LicomSync";
  return (
    <div className="text-white">
      <div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center">
        <p className="text-primary font-bold p-2">
          Empowering Your Digital Future
        </p>
        <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6 text-licom-blue">
          Transforming Your IT Landscape
        </h1>
        <div className="flex justify-center items-center">
          <p className="md:text-4xl sm:text-4xl text-xl font-bold py-4 ">
            Welcome to LicomSync - Where Innovation and Synchronization Meet!
          </p>
        </div>
        <p className="md:text-2xl text-xl font-bold text-gray-500">
          Experience the Power of Synchronization with LicomSync
        </p>
        <button
          disabled
          className="bg-licom-pink text-white font-semibold rounded-md m-4 w-72 mx-auto h-12"
        >
          <SVGLoader />
          Synchronize (Coming soon)
        </button>
      </div>
      <Software />
    </div>
  );
}

export { HomePage };
