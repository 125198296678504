import React from "react";

import { SVGLoader } from "../../components/Loaders";
import { DeviceMockup } from "./DeviceMockup";

function Software() {
  return (
    <div className="w-full bg-white py-16 px-4 text-black ">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <DeviceMockup />
        <div className="flex flex-col justify-center p-4">
          <p className="text-primary uppercase font-bold">
            Empowering Your Digital Future
          </p>
          <h1 className="md:text-4xl sm:text-3xl text-2xl py-2 font-bold">
            Experience the Power of Synchronization with LicomSync
          </h1>
          <p>
            At LicomSync, software development is our craft and passion. With a
            blend of innovation and expertise, we transform complex ideas into
            elegant, user-centric solutions.
          </p>
          <button
            disabled
            className="items-center justify-center bg-licom-yellow rounded-md m-4 w-40 md:mx-0 mx-auto h-10"
          >
            <SVGLoader />
            Coming Soon
          </button>
        </div>
      </div>
    </div>
  );
}

export { Software };
